<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="8"
      >
        <b-card
          :title="$t('Edit Car')"
        >
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <b-form-input
                  v-model="data.manufacturer"
                  class="mt-2"
                  :state="errors.manufacturer ?false:null"
                  :placeholder="$t('Manufacturer')"
                />
                <small
                  v-if="errors.manufacturer"
                  class="text-danger"
                >{{ errors.manufacturer[0] }}</small>
                <label>{{ $t('Manufacturer') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <b-form-input
                  v-model="data.model"
                  class="mt-2"
                  :state="errors.model ?false:null"
                  :placeholder="$t('Model')"
                />
                <small
                  v-if="errors.model"
                  class="text-danger"
                >{{ errors.model[0] }}</small>
                <label>{{ $t('Model') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <cleave
                  v-model="data.wight"
                  class="form-control mt-2"
                  :raw="false"
                  :options="{
                    numeral: true,
                    numeralThousandsGroupStyle: 'none',
                    numeralDecimalScale: 0,
                  }"
                  :placeholder="$t('Wight')"
                  :state="errors.wight ?false:null"
                  :class="errors.wight ?'is-invalid':null"
                />
                <small
                  v-if="errors.wight"
                  class="text-danger"
                >{{ errors.wight[0] }}</small>
                <label>{{ $t('Wight') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <cleave
                  v-model="data.wight1"
                  class="form-control mt-2"
                  :raw="false"
                  :options="{
                    numeral: true,
                    numeralThousandsGroupStyle: 'none',
                    numeralDecimalScale: 0,
                  }"
                  :placeholder="$t('Wight1')"
                  :state="errors.wight1 ?false:null"
                  :class="errors.wight1 ?'is-invalid':null"
                />
                <small
                  v-if="errors.wight1"
                  class="text-danger"
                >{{ errors.wight1[0] }}</small>
                <label>{{ $t('Wight1') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <cleave
                  v-model="data.passenger"
                  class="form-control mt-2"
                  :raw="false"
                  :options="{
                    numeral: true,
                    numeralThousandsGroupStyle: 'none',
                    numeralDecimalScale: 0,
                  }"
                  :placeholder="$t('Passenger')"
                  :state="errors.passenger ?false:null"
                  :class="errors.passenger ?'is-invalid':null"
                />
                <small
                  v-if="errors.passenger"
                  class="text-danger"
                >{{ errors.passenger[0] }}</small>
                <label>{{ $t('Passenger') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <cleave
                  v-model="data.axle"
                  class="form-control mt-2"
                  :raw="false"
                  :options="{
                    numeral: true,
                    numeralThousandsGroupStyle: 'none',
                    numeralDecimalScale: 0,
                  }"
                  :placeholder="$t('Axle')"
                  :state="errors.axle ?false:null"
                  :class="errors.axle ?'is-invalid':null"
                />
                <small
                  v-if="errors.axle"
                  class="text-danger"
                >{{ errors.axle[0] }}</small>
                <label>{{ $t('Axle') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <cleave
                  v-model="data.cylinder"
                  class="form-control mt-2"
                  :raw="false"
                  :options="{
                    numeral: true,
                    numeralThousandsGroupStyle: 'none',
                    numeralDecimalScale: 0,
                  }"
                  :placeholder="$t('Cylinder')"
                  :state="errors.cylinder ?false:null"
                  :class="errors.cylinder ?'is-invalid':null"
                />
                <small
                  v-if="errors.cylinder"
                  class="text-danger"
                >{{ errors.cylinder[0] }}</small>
                <label>{{ $t('Cylinder') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <b-form-input
                  v-model="data.motor_type"
                  class="mt-2"
                  :state="errors.motor_type ?false:null"
                  :placeholder="$t('Motor_type')"
                />
                <small
                  v-if="errors.motor_type"
                  class="text-danger"
                >{{ errors.motor_type[0] }}</small>
                <label>{{ $t('Motor_type') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <cleave
                  v-model="data.motor_power"
                  class="form-control mt-2"
                  :raw="false"
                  :options="{
                    numeral: true,
                    numeralThousandsGroupStyle: 'none',
                    numeralDecimalScale: 0,
                  }"
                  :placeholder="$t('Motor_power')"
                  :state="errors.motor_power ?false:null"
                  :class="errors.motor_power ?'is-invalid':null"
                />
                <small
                  v-if="errors.motor_power"
                  class="text-danger"
                >{{ errors.motor_power[0] }}</small>
                <label>{{ $t('Motor_power') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <cleave
                  v-model="data.motor_size"
                  class="form-control mt-2"
                  :raw="false"
                  :options="{
                    numeral: true,
                    numeralThousandsGroupStyle: 'none',
                    numeralDecimalScale: 0,
                  }"
                  :placeholder="$t('Motor_size')"
                  :state="errors.motor_size ?false:null"
                  :class="errors.motor_size ?'is-invalid':null"
                />
                <small
                  v-if="errors.motor_size"
                  class="text-danger"
                >{{ errors.motor_size[0] }}</small>
                <label>{{ $t('Motor_size') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div>
                <label>{{ $t('Car_type') }}</label>
                <b-form-select
                  v-model="data.car_type"
                  :options="options"
                  label="title"
                />
                <small
                  v-if="errors.car_type"
                  class="text-danger"
                >{{ errors.car_type[0] }}</small>
              </div>
            </b-col>

          </b-row>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-row>

          <b-col cols="12">
            <b-card>
              <b-row>
                <b-col
                  cols="12"
                >
                  <b-button
                    class="w-100 mb-1"
                    variant="gradient-success"
                    @click="update"
                  >
                    {{ $t('Update') }}
                  </b-button>
                  <b-button
                    class="w-100"
                    variant="gradient-danger"
                    @click="data={},errors=[]"
                  >
                    {{ $t('Reset Form') }}
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-col>

    </b-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      options: [
        { value: 'tourism', text: 'سياحية' },
        { value: 'small', text: 'نقل صغيرة' },
        { value: 'big', text: 'نقل كبيرة' },
        { value: 'transport_ride', text: 'نقل وركوب' },
        { value: 'agricultural', text: 'زراعية' },
        { value: 'work', text: 'أشغال' },
        { value: 'microbus', text: 'ميكروباص' },
        { value: 'motor', text: 'دراجة نارية' },
      ],
      data: {},
      errors: [],
      fileErrors: '',

    }
  },
  computed: {

    Element() { return this.$store.getters['car/GetElement'] },
  },
  created() {
    this.getData()
  },
  methods: {
    setBoolean(val) {
      if (this.data[val] === undefined) { this.data[val] = false }
    },
    UpdateFileError(variable) {
      this.fileErrors = variable
    },
    getData() {
      this.$store.dispatch('car/GetElement', this.$route.params.car).then(() => {
        this.data = this.Element
      })
    },
    update() {
      this.errors = []
      this.$store.dispatch('car/Update', this.data).then(() => {
      }).catch(error => {
        this.errors = error.response.data.errors
      })
    },
  },
}
</script>
